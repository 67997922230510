<template>
  <div class="login-section section" v-if="$locale">
    <div class="login-section-form">
      <div class="login-section-form-container">
        <div class="login-section-form-container-inner">
          <div class="login-section-picture">
            <AppLogo />
          </div>
          <div :route="this.$route.path">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from "../app/AppLogo.vue";
export default {
  components: { AppLogo },
};
</script>

<style lang="scss">
.login-section {
  display: flex;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: $white_smoke;
  &-form {
    width: 100%;
    max-width: 380px;
    padding: $mpadding;
    margin: 0 auto;
    &-container {
      position: relative;
      padding: $mpadding;
      border-radius: $mradius/2;
      box-shadow: $dshadow;
      display: flex;
      align-items: center;
      overflow: hidden;
      &-inner {
        position: relative;
        margin: auto;
        z-index: 2;
        width: 100%;
      }
    }
    &-title {
      font-size: 32px;
      margin: $mpadding 0;
    }
  }
  &-tabs {
    text-align: right;
    margin-top: $mpadding;
    position: relative;
    z-index: 2;
    .tab-btn {
      display: inline-block;
      padding: $mpadding;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
      position: relative;
      border-top-left-radius: $mpadding;
      border-top-right-radius: $mpadding;
      padding-bottom: $mpadding * 2;
      margin-bottom: -$mpadding;
      max-width: 50%;
      &:first-child {
        box-shadow: 1px 0 7px 0px rgba(0, 0, 0, 0.25);
      }
    }
    &-buttons {
      white-space: nowrap;
    }
  }
  &-title {
    text-align: left;
    padding: $mpadding;
    font-weight: bold;
  }
  &-data {
    padding: $mpadding;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: $mpadding;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: $mpadding * 1.75;
      top: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 2;
      border-radius: $mpadding;
    }
    &[route="/signup"] {
      border-radius: $mpadding $mpadding $mpadding $mpadding;
    }
  }
  &-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $mpadding/2 0 0 $mpadding/2;
    a.link-btn {
      display: flex;
      align-items: center;
      font-size: 80%;
      cursor: pointer;
      margin-top: $mpadding;
      input + *:not(:empty) {
        margin-left: $mpadding/2;
      }
      label {
        display: flex;
        align-items: center;
        padding: 0;
      }
      .mycheck {
        width: 20px;
        height: 20px;
      }
      &.underline {
        border-bottom: solid 1px;
      }
    }
  }
  &-signin-with {
    margin-top: $mpadding;
  }
}
</style>
